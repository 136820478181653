import React, { useState } from 'react';
import './header.css';


const Header = () => {

    /* ================ show menu ================ */
        const[Toggle, showMenu] = useState(false);
    return (
    <header className="header">
        <nav className='nav container'>
            <a href="index.html" className="nav__logo">
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="30.000000pt" height="30.000000pt" viewBox="0 0 500.000000 500.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M2738 4695 c-17 -17 -168 -46 -288 -55 -166 -13 -203 -18 -260 -37
-114 -37 -249 -134 -331 -239 -47 -60 -65 -107 -128 -329 -10 -38 -44 -155
-74 -260 -31 -104 -72 -246 -91 -315 -20 -69 -42 -145 -49 -170 -8 -25 -45
-158 -83 -297 -38 -138 -70 -250 -72 -249 -1 2 9 81 23 177 26 180 44 304 70
494 8 61 22 160 31 220 8 61 18 144 21 185 9 117 -8 122 -175 43 -65 -30 -79
-43 -128 -109 -30 -41 -83 -97 -117 -123 -60 -47 -107 -111 -107 -145 0 -27
-127 -152 -206 -203 -78 -50 -139 -91 -212 -144 -24 -17 -80 -44 -124 -59 -99
-35 -147 -77 -148 -130 0 -20 4 -40 9 -45 5 -6 26 -50 46 -100 20 -49 41 -96
47 -102 6 -7 14 -62 18 -122 9 -122 23 -158 90 -233 43 -49 77 -61 99 -35 6 8
17 49 26 92 8 43 17 81 21 84 7 7 -3 -125 -17 -214 -5 -33 -9 -96 -9 -140 0
-68 5 -94 36 -172 25 -66 45 -100 70 -122 31 -28 40 -30 97 -28 l62 2 20 44
c19 43 19 43 62 36 43 -6 68 2 99 32 9 9 28 17 42 18 16 0 31 8 37 20 31 60
46 74 65 63 14 -9 22 -9 32 0 11 9 16 -1 26 -52 11 -60 10 -66 -12 -109 -66
-123 -116 -325 -116 -466 0 -47 7 -128 15 -178 8 -51 21 -163 29 -249 16 -176
11 -238 -30 -314 -27 -51 -30 -82 -10 -99 27 -22 104 11 213 91 l52 38 51 163
c87 275 156 495 205 652 25 83 50 161 55 175 5 14 27 86 50 160 22 74 47 153
55 175 7 22 34 108 60 190 93 297 97 303 45 55 -17 -80 -35 -163 -39 -185 -5
-22 -23 -105 -40 -185 -18 -80 -36 -167 -41 -195 -5 -27 -14 -68 -20 -90 -5
-22 -28 -128 -50 -235 -38 -183 -50 -241 -85 -400 -51 -233 -75 -355 -75 -383
0 -25 13 -46 55 -92 31 -33 69 -89 86 -125 45 -95 45 -95 61 -98 21 -4 228
280 250 342 9 28 18 71 18 96 l1 45 25 -30 c36 -43 62 -97 70 -142 20 -125
114 -77 114 59 0 32 2 58 5 58 3 0 22 -9 42 -20 47 -25 79 -25 92 -1 9 16 32
116 76 336 8 39 22 102 30 140 20 93 63 293 80 380 8 39 22 102 30 140 9 39
20 90 25 115 5 25 23 110 40 190 17 80 38 179 46 220 9 41 20 93 24 115 20 89
71 334 85 400 8 39 26 124 40 190 14 66 33 154 41 195 9 41 22 102 29 135 7
33 25 119 40 190 15 72 33 157 40 190 7 33 20 94 29 135 38 180 67 320 82 390
9 41 22 102 29 135 7 33 24 118 39 188 15 71 26 143 24 160 l-3 32 -145 -1
c-80 -1 -177 -8 -217 -16 -68 -14 -71 -14 -60 4 9 13 9 23 1 36 -11 17 -54 28
-66 17z m22 -135 c16 0 81 9 145 20 64 11 151 20 192 20 66 0 74 -2 69 -17 -3
-10 -14 -63 -26 -118 -33 -158 -51 -245 -65 -310 -7 -33 -24 -116 -39 -185
-28 -135 -82 -390 -96 -455 -4 -22 -16 -76 -25 -120 -10 -44 -23 -107 -30
-140 -7 -33 -20 -94 -29 -135 -8 -41 -29 -140 -46 -220 -17 -80 -35 -165 -40
-190 -5 -25 -23 -110 -40 -190 -17 -80 -35 -165 -40 -190 -5 -25 -16 -76 -25
-115 -8 -38 -22 -101 -30 -140 -17 -87 -60 -287 -80 -380 -8 -38 -22 -101 -30
-140 -14 -69 -35 -171 -95 -455 -17 -80 -41 -193 -53 -252 -13 -59 -26 -110
-29 -114 -4 -3 -28 8 -54 25 -30 21 -56 31 -73 29 -26 -3 -26 -4 -24 -73 l3
-70 -29 50 c-39 68 -109 145 -131 145 -29 0 -39 -22 -48 -106 -12 -104 -26
-134 -119 -258 l-78 -104 -17 35 c-10 20 -45 69 -79 110 l-61 73 31 148 c18
81 39 179 47 217 32 147 45 206 84 395 23 107 47 224 55 260 14 63 79 363 96
445 4 22 22 105 39 185 17 80 37 177 45 215 8 39 24 113 35 165 11 52 30 140
42 195 27 125 29 184 6 198 -25 17 -51 -12 -72 -80 -10 -32 -35 -112 -56 -178
-21 -66 -52 -167 -70 -225 -17 -58 -38 -123 -45 -145 -8 -22 -32 -101 -55
-175 -23 -74 -47 -153 -55 -175 -7 -22 -32 -101 -55 -175 -22 -74 -47 -153
-55 -175 -7 -22 -32 -101 -55 -175 -23 -74 -59 -189 -80 -255 -21 -66 -66
-210 -100 -320 -34 -110 -69 -207 -78 -217 -10 -9 -43 -33 -75 -53 l-58 -36
15 28 c33 64 38 144 21 327 -8 96 -22 211 -30 257 -8 46 -15 116 -15 155 0
124 46 308 112 442 24 49 29 72 25 100 -4 20 -11 65 -16 100 -5 35 -15 70 -22
78 -15 18 -43 18 -59 -1 -7 -9 -22 -12 -39 -9 -24 5 -33 -1 -67 -45 -23 -30
-47 -50 -59 -51 -11 0 -32 -13 -47 -29 -26 -27 -31 -28 -68 -20 -50 11 -70 2
-95 -44 -14 -27 -26 -36 -52 -39 -45 -5 -64 17 -104 121 -29 78 -31 89 -26
170 6 86 24 253 52 496 27 234 38 354 32 369 -9 22 -50 20 -65 -4 -6 -11 -28
-95 -47 -187 -20 -93 -42 -195 -49 -228 -8 -33 -22 -99 -32 -147 -18 -84 -19
-87 -37 -70 -40 36 -58 92 -67 201 -6 80 -14 118 -33 156 -13 28 -32 68 -41
90 -10 22 -25 55 -33 73 -16 31 -15 33 7 57 13 14 46 32 74 40 72 22 135 53
186 95 25 20 93 66 151 103 103 65 222 176 246 230 6 12 17 39 26 59 10 23 37
52 71 77 53 40 66 54 138 150 18 25 47 52 63 61 55 29 115 53 120 48 3 -3 -1
-54 -10 -114 -8 -60 -22 -158 -30 -219 -39 -284 -89 -630 -120 -820 -8 -55
-23 -147 -32 -205 -23 -144 -23 -219 -1 -234 27 -17 52 18 73 99 9 39 23 93
31 120 8 28 57 208 109 400 53 193 102 373 110 400 8 28 31 106 51 175 19 69
60 208 89 310 167 579 157 553 251 639 158 145 232 170 564 196 41 3 98 11
125 18 l50 13 14 -28 c10 -21 21 -28 41 -28z m-1403 -1852 c-3 -8 -6 -5 -6 6
-1 11 2 17 5 13 3 -3 4 -12 1 -19z m-700 -180 c-3 -8 -6 -5 -6 6 -1 11 2 17 5
13 3 -3 4 -12 1 -19z"/>
<path d="M3589 4383 c-17 -40 -68 -192 -109 -328 -5 -16 -23 -95 -40 -175 -17
-80 -37 -174 -45 -210 -15 -66 -94 -441 -166 -785 -22 -104 -46 -219 -54 -255
-8 -36 -33 -153 -55 -260 -22 -107 -47 -224 -55 -260 -8 -36 -28 -132 -45
-215 -17 -82 -37 -177 -45 -210 -8 -33 -21 -94 -29 -135 -9 -41 -29 -140 -46
-220 -17 -80 -35 -165 -40 -190 -5 -25 -19 -88 -30 -140 -40 -178 -42 -169 54
-239 101 -75 248 -120 276 -86 19 23 -3 62 -83 150 -34 37 -75 89 -90 116
l-28 48 51 -25 c110 -53 154 -66 250 -71 87 -5 97 -3 123 17 15 12 27 28 27
35 0 19 -36 42 -92 59 -66 20 -99 46 -178 144 l-70 87 138 7 c186 9 181 10
342 -107 45 -33 88 -64 96 -70 19 -14 48 6 52 35 5 29 -88 210 -140 273 l-39
47 53 -21 c68 -26 123 -59 173 -104 27 -24 48 -35 65 -33 40 5 51 40 48 154
l-3 100 27 -15 c25 -15 29 -15 44 1 15 15 16 24 7 62 -11 51 -2 53 17 4 6 -18
16 -42 20 -53 5 -11 13 -33 18 -50 11 -38 49 -45 78 -14 17 19 19 35 18 143
-1 134 -22 235 -58 274 -14 16 -19 34 -18 60 3 38 -33 165 -63 222 -9 18 -14
34 -12 36 3 3 25 -5 50 -17 44 -22 47 -22 66 -5 11 10 24 42 30 74 9 48 46
226 81 387 4 22 16 76 25 120 9 44 23 109 31 145 29 130 29 148 4 175 -23 24
-23 25 -3 25 35 0 50 32 73 155 12 66 28 143 36 170 16 59 44 194 44 214 0 8
-24 36 -52 64 -31 28 -48 51 -41 54 28 10 63 46 63 63 0 22 -16 35 -55 44 -98
24 -187 100 -320 271 -22 29 -68 89 -103 133 -72 94 -172 172 -220 172 -27 0
-34 -6 -53 -47z m119 -53 c22 -16 71 -69 109 -117 120 -156 136 -175 231 -274
59 -60 109 -103 135 -115 23 -10 44 -19 46 -20 2 -2 -9 -14 -25 -27 -26 -23
-27 -27 -15 -48 7 -13 37 -43 66 -66 l52 -42 -18 -88 c-10 -48 -23 -98 -29
-111 -5 -13 -10 -34 -10 -47 0 -13 -9 -69 -21 -123 l-21 -99 -50 -6 c-110 -13
-118 -81 -15 -118 31 -11 37 -17 32 -34 -3 -11 -13 -54 -21 -95 -9 -41 -22
-102 -29 -135 -7 -33 -20 -94 -28 -135 -9 -41 -28 -129 -42 -195 -14 -66 -29
-137 -32 -158 -3 -21 -9 -40 -13 -42 -5 -3 -29 4 -55 15 -64 27 -98 26 -115
-4 -11 -22 -10 -30 19 -74 48 -76 84 -186 85 -262 1 -56 4 -66 22 -74 25 -11
50 -102 60 -216 l6 -75 -33 64 c-19 35 -47 73 -62 85 -26 19 -30 19 -55 5 -19
-10 -28 -24 -30 -47 -3 -27 -7 -32 -30 -32 -45 0 -55 -29 -37 -106 8 -36 15
-92 15 -125 l0 -61 -42 37 c-85 73 -256 138 -304 117 -37 -17 -30 -47 26 -109
45 -51 130 -193 115 -193 -4 0 -32 20 -63 44 -104 81 -102 80 -332 80 l-205 0
-3 -32 c-3 -28 8 -47 74 -130 85 -105 123 -145 169 -174 l30 -20 -40 7 c-53 8
-133 38 -219 81 -38 19 -75 33 -83 30 -15 -6 -15 -5 -4 49 5 22 16 74 24 115
9 41 34 163 57 270 23 107 55 258 71 335 17 77 34 160 39 185 5 25 23 110 40
190 41 192 76 355 110 520 16 74 47 223 70 330 22 107 52 247 65 310 14 63 34
158 45 210 11 52 30 140 41 195 56 273 151 585 178 585 4 0 26 -13 49 -30z
m-797 -3382 c20 -46 38 -72 86 -127 70 -80 71 -83 24 -66 -65 22 -163 93 -157
113 3 9 10 36 16 60 12 48 17 51 31 20z"/>
<path d="M4530 3588 c-38 -41 -16 -97 32 -83 55 17 73 58 40 88 -25 23 -47 21
-72 -5z"/>
<path d="M4462 3228 c-7 -7 -12 -24 -12 -38 0 -25 11 -39 72 -86 l28 -22 -28
-11 c-15 -6 -45 -14 -66 -19 -46 -9 -66 -33 -50 -59 16 -25 92 -21 166 6 113
43 153 91 98 117 -14 7 -42 20 -63 31 -21 10 -54 35 -74 55 -40 41 -52 45 -71
26z"/>
<path d="M4383 2918 c-16 -21 -67 -278 -68 -338 l0 -55 59 -12 c32 -6 109 -14
170 -18 105 -7 112 -6 130 14 18 20 18 25 7 59 -42 117 -101 213 -184 299 -60
63 -93 77 -114 51z m107 -132 c47 -58 87 -127 113 -194 l17 -42 -82 6 c-146
12 -163 17 -161 45 7 72 48 239 58 239 7 0 31 -24 55 -54z"/>
<path d="M4273 2398 c-6 -7 -16 -43 -24 -81 -16 -83 -11 -90 88 -123 98 -33
137 -38 152 -20 16 19 11 57 -9 66 -56 26 -76 44 -113 101 -42 64 -73 83 -94
57z m57 -118 c11 -21 4 -25 -19 -11 -7 5 -10 14 -7 20 10 15 14 14 26 -9z"/>
<path d="M4222 2148 c-15 -20 -32 -88 -32 -134 0 -32 7 -49 29 -73 32 -34 52
-39 71 -16 18 22 5 193 -17 217 -19 21 -37 23 -51 6z"/>
<path d="M2766 668 c-21 -30 -20 -50 4 -76 37 -40 100 -40 108 0 4 22 -55 98
-77 98 -11 0 -27 -10 -35 -22z"/>
</g>
</svg>


            </a>

            <div className={Toggle ? "nav__menu show-menu" : 
            "nav__menu"}>
                <ul className="nav__list grid">
                    <li className="nav__item">
                        <a href="#home" className="nav__link active-link">
                            <i className="uil uil-estate nav__icon"></i> Home
                        </a>
                    </li>
                    <li className="nav__item">
                        <a href="#about" className="nav__link">
                            <i className="uil uil-user nav__icon"></i> About
                        </a>
                    </li>
                    <li className="nav__item">
                        <a href="#skills" className="nav__link">
                            <i className="uil uil-file-alt nav__icon"></i> Skills
                        </a>
                    </li>
                    {/* <li className="nav__item">
                        <a href="#services" className="nav__link">
                            <i className="uil uil-briefcase-alt nav__icon"></i> Services
                        </a>
                    </li> */}
                    {/* <li className="nav__item">
                        <a href="#portfolio" className="nav__link">
                            <i className="uil uil-scenery nav__icon"></i> Portfolio
                        </a>
                    </li> */}
                    {/* <li className="nav__item">
                        <a href="#contact" className="nav__link">
                            <i className="uil uil-message nav__icon"></i> Contact
                        </a>
                    </li> */}
                </ul>
                <i className="uil uil-times nav__close" onClick={() => showMenu(!Toggle)}></i>
            </div>

            <div className='nav__toggle' onClick={() => showMenu(!Toggle)}>
                <i className="uil uil-apps"></i>
            </div>
        </nav>
    </header>

  )
}

export default Header