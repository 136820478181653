import React from 'react'

const Embeded = () => {
  return (
    /* bu kısımda beni gömülü bilgilerim yer alacak yani arduino smt32 yada esp32 gibi */
    <div className="skills__content">
    <h3 className="skills__title">Embeded</h3>

    <div className="skills__box">
          <div className="skills__group">
              <div className="skills__data">
              <i class='bx bx-badge-check'></i>

              <div>
                  <h3 className="skills__name">Stm32</h3>
                  <span className="skills_level">intermediate</span>
              </div>
              </div>
              <div className="skills__data">
              <i class='bx bx-badge-check'></i>

              <div>
                  <h3 className="skills__name">Esp32</h3>
                  <span className="skills_level">Advanced</span>
              </div>
              </div>
              <div className="skills__data">
              <i class='bx bx-badge-check'></i>

              <div>
                  <h3 className="skills__name">Arduino</h3>
                  <span className="skills_level">Advanced</span>
              </div>
              </div>
              
          </div>
          <div className="skills__group">
              <div className="skills__data">
              <i class='bx bx-badge-check'></i>

              <div>
                  <h3 className="skills__name">Eagle</h3>
                  <span className="skills_level">intermediate</span>
              </div>
              </div>
              <div className="skills__data">
              <i class='bx bx-badge-check'></i>

              <div>
                  <h3 className="skills__name">KiCad</h3>
                  <span className="skills_level">Basic</span>
              </div>
              </div>
              
          </div>
          
    </div>
  </div>
  )
}

export default Embeded