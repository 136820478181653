import React from 'react';
import './App.css';
import Header from './components/header/header';
import Home from './components/home/home';
import About from './components/about/about';
import Skills from './components/skills/skills';
import Services from './components/services/services';
import Qualification from './components/qualification/Qualification';

function App() {
  return (
    <div>
      <Header />

      <main className='main'>
        <Home />
        <About />
        <Skills />
        <Qualification />
      </main>
    </div>
  );
}

export default App;

