import React from 'react'

const Info = () => {
  return (
    <div className="about__info grid">
        <div className="about__box">
        <i class="uil uil-briefcase-alt"></i>
            <h3 className="about__title">Experience</h3>
            <span className="about__subtitle">3 Years</span>
        </div>
        <div className="about__box">
            <i class="uil uil-award"></i>
            <h3 className="about__title">Event</h3>
            <span className="about__subtitle">3+</span>
        </div>
        <div className="about__box">
            <i class="uil uil-file-alt"></i>
            <h3 className="about__title">Projects</h3>
            <span className="about__subtitle">10+</span>
        </div>
    </div>
  )
}

export default Info