import React from 'react';

const Social = () => {
    return (
        <div className="home__social">
            <a href="https://instagram.com/_mustafaslan0" className="home__social-icon" target='__blank'>
            <i class="uil uil-instagram"></i>
            </a>

            <a href="https://github.com/mustafaslan0" className="home__social-icon" target='__blank'>
            <i class="uil uil-github-alt"></i>
            </a>

            <a href="https://www.linkedin.com/in/mustafa-aslan-399b8222b" className="home__social-icon" target='__blank'>
            <i class="uil uil-linkedin-alt"></i>
            </a>
        </div>
    )
}

export default Social