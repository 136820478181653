import React from 'react'

const Software = () => {
  return (
    /* bu kısımda beni yazılım bilgilerim yer alacak yani python c html css javascript react */
    
    <div className="skills__content">
      <h3 className="skills__title">Software</h3>

      <div className="skills__box">
            <div className="skills__group">
                <div className="skills__data">
                <i class='bx bx-badge-check'></i>

                <div>
                    <h3 className="skills__name">ROS</h3>
                    <span className="skills_level">Advanced</span>
                </div>
                </div>
                <div className="skills__data">
                <i class='bx bx-badge-check'></i>

                <div>
                    <h3 className="skills__name">C++</h3>
                    <span className="skills_level">intermediate</span>
                </div>
                </div>
                <div className="skills__data">
                <i class='bx bx-badge-check'></i>

                <div>
                    <h3 className="skills__name">PYTHON</h3>
                    <span className="skills_level">Advanced</span>
                </div>
                </div>
                
            </div>
            <div className="skills__group">
                <div className="skills__data">
                <i class='bx bx-badge-check'></i>

                <div>
                    <h3 className="skills__name">REACT.JS</h3>
                    <span className="skills_level">Basic</span>
                </div>
                </div>
                <div className="skills__data">
                <i class='bx bx-badge-check'></i>

                <div>
                    <h3 className="skills__name">JavaScript</h3>
                    <span className="skills_level">intermediate</span>
                </div>
                </div>
                <div className="skills__data">
                <i class='bx bx-badge-check'></i>

                <div>
                    <h3 className="skills__name">C</h3>
                    <span className="skills_level">intermediate</span>
                </div>
                </div>
                
            </div>
            
      </div>
    </div>
  )
}

export default Software